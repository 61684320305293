import { checkDevice } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import jsAssets from "Assets/js/modules/jsAssets";
import checkBrowserInfo from "Assets/js/modules/check-browser-info";
import colourPalettes from "@pernod-ricard-global-cms/cblcolourpalettes";
import siteHeader from "Assets/js/components/site-header";
checkDevice();
checkBrowserInfo();
globalThis.requestAnimationFrame(() => {
    const colourData = globalThis.colourconfig;
    colourPalettes(colourData);
    const header = document.querySelector(".site-header");
    siteHeader({ block: header });
});
lazyloader.options.assetArray = jsAssets.dynamicAssets;
lazyloader.options.rootMargin = "100% 0px 500px 0px";
lazyloader.options.filePath = "js/blocks/";
// lazyloader.options.debugLogMessages = false;
lazyloader.lazyloaderInit();
